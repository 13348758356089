import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";
import typedJs from "https://cdn.jsdelivr.net/npm/typed.js@2.0.16/+esm";
import { CountUp } from "./plugins/countUp.js";
import { getPosition } from "./meta-settings.js";
import { initAnimationOnView } from "./meta-settings.js";

// Init animation on view
initAnimationOnView({
    threshold: 0.2,
});

const vh = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
};

vh();

window.onresize = () => vh();

const intersectionItems = document.querySelectorAll(".intersect");
if (intersectionItems.length) {
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                const targ = entry.target;

                if (entry.isIntersecting && targ.nextElementSibling) {
                    targ.classList.add("showed");

                    let stringsList = Array.from(
                        targ.nextElementSibling.children
                    ).map((item) => item.textContent);

                    if (targ.classList.contains("typed") && !targ.textContent) {
                        new typedJs(targ, {
                            stringsElement: targ.nextElementSibling,
                            typeSpeed: 50,
                            backSpeed: 50,
                            startDelay: 0,
                            backDelay: 500,
                            showCursor: false,
                            loop: false,
                        });
                    } else if (
                        targ.classList.contains("typed-list") &&
                        !targ.textContent
                    ) {
                        if (window.innerWidth < 441) {
                            stringsList = stringsList.map((item) =>
                                item.replace(/ /g, "<br>")
                            );
                        }

                        const typed = new typedJs(targ, {
                            strings: stringsList,
                            typeSpeed: 40,
                            backSpeed: 40,
                            startDelay: 50,
                            backDelay: 800,
                            showCursor: false,
                            loop: true,
                        });

                        if (targ.parentElement.matches(".btn")) {
                            targ.parentElement.addEventListener(
                                "mouseenter",
                                () => {
                                    typed.stop();
                                }
                            );

                            targ.parentElement.addEventListener(
                                "mouseleave",
                                () => {
                                    typed.start();
                                }
                            );
                        }
                    }
                }
            });
        },
        {
            threshold: 0,
            rootMargin: "0% 0% -20% 0%",
        }
    );

    intersectionItems.forEach((target) => {
        observer.observe(target);
    });
}

const header = document.getElementsByClassName(`header`)[0];
// Header scroll
const scrollContainer = () => {
    return document.documentElement || document.body;
};

if (header.classList.contains("fixed")) {
    document.addEventListener("scroll", () => {
        if (scrollContainer().scrollTop > 0) {
            header.classList.add("scrolled");
        } else if (scrollContainer().scrollTop === 0) {
            header.classList.remove("scrolled");
        }
    });
}

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
    document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".header-mobile").length;

// toggle menu handler
function menuToggle() {
    $(".menu-toggle").toggleClass("active");
    $(".mobile-header").toggleClass("active");
    $(".header-close-wrapper").toggleClass("active");
    // LockScroll when burger open and enable when closed and enable scroll on menu
    if (scrollLock.getScrollState()) {
        scrollLock.disablePageScroll(
            document.querySelector(".navbar-nav .menu")
        );
        scrollLock.addScrollableSelector(
            ".mobile-header .simplebar-content-wrapper"
        );
    } else {
        scrollLock.enablePageScroll();
    }
}

//menu update function
function updateMenu() {
    isMobileMenuEnable =
        $(window).outerWidth() <= mobileMenuStartPoint ||
        $(".mobile-header").length;
    if (!isMobileMenuEnable) {
        $(".dropdown-menu").css("display", "");
        $(".header-close-wrapper").removeClass("active");
        $(".menu-item.active").removeClass("active");
        $(".navbar-nav").removeClass("active");
        $(".menu-toggle").removeClass("active");
        // LockScroll when burger open and enable when closed
        scrollLock.enablePageScroll();
    }
}

window.formatNumberInput = function (input) {
    input.value = input.value.replace(/[^0-9]/g, "");
};

$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

const homepageVideo = document.querySelector(".hero-screen--main video");
if (homepageVideo) homepageVideo.playbackRate = 0.7;

function initTilt(tiltElements) {
    if (window.innerWidth > 1024 && tiltElements.length) {
        VanillaTilt.init(tiltElements, {
            scale: 1.05,
        });
    }
}

const tiltElements = document.querySelectorAll(".tilt");
initTilt(tiltElements);

/* ---- Mutation Observer ---- */
const mutationObserverOptions = { childList: true, subtree: true };

const jobsBoard = document.querySelector(".job-board");
if (jobsBoard) {
    // Callback function to handle the mutations
    const handleJobsMutations = function (mutationsList) {
        for (const mutation of mutationsList) {
            if (mutation.addedNodes.length) {
                let topOffset = jobsBoard.offsetTop;

                const cards = Array.from(mutation.addedNodes).filter((el) => {
                    if (
                        el instanceof HTMLElement &&
                        el.classList.contains("tilt")
                    ) {
                        return el;
                    }
                });

                initTilt(cards);

                if (window.innerWidth <= 769) {
                    const jobsFilters = jobsBoard.querySelector(
                        ".job-board__filters"
                    );
                    console.log(jobsFilters.offsetHeight);

                    topOffset += jobsFilters.offsetHeight;
                }

                window.scrollTo({
                    top: topOffset,
                    behavior: "smooth",
                });
            }
        }
    };

    // Create a MutationObserver instance
    const mutationObserver = new MutationObserver(handleJobsMutations);

    const mutationObserverOptions = { childList: true, subtree: true };

    const jobResults = jobsBoard.querySelector(".job-board__list");

    mutationObserver.observe(jobResults, mutationObserverOptions);
}

const blogPage = document.querySelector(".blogs");
if (blogPage) {
    // Callback function to handle the mutations
    const handleBlogsMutations = function (mutationsList) {
        for (const mutation of mutationsList) {
            if (mutation.addedNodes.length) {
                let topOffset = blogPage.offsetTop;

                window.scrollTo({
                    top: topOffset,
                    behavior: "smooth",
                });
            }
        }
    };

    // Create a MutationObserver instance
    const mutationObserver = new MutationObserver(handleBlogsMutations);

    const blogResults = blogPage.querySelector(".blogs__list");

    mutationObserver.observe(blogResults, mutationObserverOptions);
}

/* ---- Mutation Observer end ---- */

// Count up
const counts = Array.from(document.getElementsByClassName("count-up"));
if (counts) {
    const defaultOptions = {
        separator: "",
        enableScrollSpy: true,
        scrollSpyRunOnce: true,
    };

    let idNumber = 1;

    counts.forEach((count) => {
        const id = `count-up-${idNumber}`,
            value = parseFloat(count.innerHTML);

        let optionsFromDataAttr = $(count).data();

        for (const key in optionsFromDataAttr) {
            if (optionsFromDataAttr[key] === "") {
                optionsFromDataAttr[key] = true;
            }
        }

        count.id = id;
        new CountUp(
            id,
            value,
            Object.assign(
                Object.assign({}, defaultOptions),
                optionsFromDataAttr
            )
        );
        idNumber++;
    });
}

// Buttons
const buttons = document.querySelectorAll(".btn");
if (buttons.length) {
    buttons.forEach((btn) => {
        const ifButtonAnimated = btn.classList.contains("btn-animation");

        if (!ifButtonAnimated) {
            const text = btn.textContent.trim().toLowerCase();

            btn.innerHTML = `<span>${
                text.charAt(0).toUpperCase() + text.slice(1)
            }</span>`;
        }
    });
}

// Button animations
const animationButtons = document.querySelectorAll(".btn-animation");
if (animationButtons.length) {
    animationButtons.forEach((btn) => {
        const listItems = btn.querySelectorAll("span");

        let currentIndex = 0;

        function addClassToNextElement() {
            for (let i = 0; i < listItems.length; i++) {
                listItems[i].classList.remove("animated");
            }

            listItems[currentIndex].classList.add("animated");

            currentIndex = (currentIndex + 1) % listItems.length;
        }

        let intervalId;

        function pauseAnimation() {
            clearInterval(intervalId);
        }

        function resumeAnimation() {
            intervalId = setInterval(addClassToNextElement, 2200);
        }

        intervalId = setInterval(addClassToNextElement, 2200);

        btn.addEventListener("mouseenter", pauseAnimation);
        btn.addEventListener("mouseleave", resumeAnimation);
    });
}

// Choices
const selectItems = document.querySelectorAll(".select");
if (selectItems.length) {
    selectItems.forEach((select) => {
        const choice = new Choices(select, {
            searchEnabled: false,
            shouldSort: false,
            itemSelectText: "",
        });
    });
}

// Format numbers
function formatNumber(number) {
    const parts = number.toString().split(".");

    const integerPart = parts[0];

    const decimalPart = parts[1] || "";

    const formattedIntegerPart = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
    );

    return decimalPart
        ? `${formattedIntegerPart}.${decimalPart}`
        : formattedIntegerPart;
}

const formattedNumbers = document.querySelectorAll(".formatted");
if (formattedNumbers.length) {
    formattedNumbers.forEach(
        (el) => (el.innerHTML = formatNumber(el.innerText))
    );
}

function debounce(callback, delay = 300) {
    let timer;

    return function () {
        clearTimeout(timer);

        timer = setTimeout(() => {
            callback.apply(this, [...arguments]);
        }, delay);
    };
}

// Solutions
// const solutionsTriggers = document.querySelectorAll(".hero-screen__solution");
// if (solutionsTriggers.length) {
//     const solutionBlocks = document.querySelectorAll(".solutions-block");
//
//     solutionsTriggers.forEach((trigger, idx) => {
//         trigger.addEventListener("click", () => {});
//     });
// }

// Range slider
const doubleRange = document.querySelector(".double-range-tooltips");
if (doubleRange) {
    const slider = doubleRange.querySelector("#double-range-tooltips");
    const max = +slider.dataset.max;
    const min = +slider.dataset.min;
    const unit = slider.dataset?.unit || "£";
    const step = +slider.dataset.step;
    const inputsHidden = doubleRange.querySelectorAll(
        ".double-range-hidden-input"
    );
    const startValueMin = +inputsHidden[0].value;
    const startValueMax = +inputsHidden[1].value;

    // how many percentages limit from borders ???? is 8%
    const borderLimit = 8;

    // each step is go backward for this amount of % ???? is 5%
    const borderDiff = 40 / borderLimit;

    noUiSlider.create(slider, {
        start: [startValueMin, startValueMax],
        connect: true,
        tooltips: true,
        margin: 10,
        step: step,
        range: {
            min: min,
            max: max,
        },
    });

    const tooltipsArr = slider.querySelectorAll(".noUi-tooltip");
    const circlesArr = slider.querySelectorAll(".noUi-origin");

    function returnTransform(element) {
        return element
            .replace(/[^0-9][^\d.]/g, "")
            .replace(")", "")
            .split(" ")
            .map((str) => {
                return Number(str);
            });
    }

    function needToMerge() {
        let tooltipOnePosition = tooltipsArr[0].getBoundingClientRect();
        let tooltipTwoPosition = tooltipsArr[1].getBoundingClientRect();

        if (
            tooltipsArr[0].classList.contains("hidden") ||
            tooltipsArr[1].classList.contains("hidden")
        ) {
            return true;
        }

        return (
            tooltipOnePosition.left +
                tooltipOnePosition.width -
                tooltipTwoPosition.left >
            0
        );
    }

    function resetTooltips(values) {
        mergeDiff = null;
        tooltipsArr.forEach((elem, index) => {
            elem.textContent =
                Math.round(values[index]).toLocaleString("en-GB") + unit;
            elem.classList.remove("hidden");
        });
    }

    let trueTooltip = false;
    let mergeDiff = null;

    slider.noUiSlider.on("update", function (values, handle) {
        // translate of noUISlider -> 0% is start, 100% is end
        let translate = returnTransform(circlesArr[handle].style.transform)[0];

        // min value of double range slider
        let valueMin = returnTransform(circlesArr[0].style.transform)[0];

        // max value of double range slider
        let valueMax = returnTransform(circlesArr[1].style.transform)[0];

        // difference between min and max value of double range slider
        let difference = valueMax - valueMin;

        inputsHidden[handle].value = Math.round(values[handle]);

        // if tooltips are close to each other
        if (needToMerge()) {
            if (
                !tooltipsArr[+!handle].classList.contains("hidden") &&
                !tooltipsArr[handle].classList.contains("hidden")
            ) {
                trueTooltip = handle;
                mergeDiff = difference;
                tooltipsArr[+!handle].classList.add("hidden");
            }

            if (trueTooltip) {
                // limit left merged tooltip from overflowing
                // borderLimit * 3 === need for 3 times faster limitation because of merged tooltip
                if (translate <= -100 + borderLimit * 3) {
                    tooltipsArr[trueTooltip].style.transform = `translate(${
                        -50 -
                        difference +
                        (Math.abs(translate + 100 - borderLimit * 3) *
                            borderDiff) /
                            2.5
                    }%,100%)`;
                } else {
                    // position of tooltip in the middle of range
                    tooltipsArr[trueTooltip].style.transform = `translate(${
                        -50 - difference
                    }%,100%)`;
                }
            } else {
                // if left tooltip is grabbed
                if (translate >= -borderLimit * 4) {
                    // limit right merged tooltip from overflowing
                    tooltipsArr[trueTooltip].style.transform = `translate(${
                        -50 +
                        difference -
                        (Math.abs(translate + borderLimit * 3) * borderDiff) /
                            2.5
                    }%,100%)`;
                } else {
                    tooltipsArr[trueTooltip].style.transform = `translate(${
                        -50 + difference
                    }%,100%)`;
                }
            }

            tooltipsArr[trueTooltip].textContent = `${
                Math.round(values[0]).toLocaleString("en-GB") + unit
            } - ${Math.round(values[1]).toLocaleString("en-GB") + unit}`;

            if (mergeDiff - difference < 0) {
                mergeDiff = null;
                resetTooltips(values);
            }
        } else {
            // limit left solo tooltip from overflowing
            if (translate <= -100 + borderLimit) {
                tooltipsArr[0].style.transform = `translate(${
                    -50 + Math.abs(translate + 100 - borderLimit) * borderDiff
                }%,100%)`;
            } else if (translate >= -borderLimit) {
                // limit right solo tooltip from overflowing
                tooltipsArr[1].style.transform = `translate(${
                    -50 - Math.abs(translate + borderLimit) * borderDiff
                }%,100%)`;
            } else {
                // position of tooltip in the middle of range
                tooltipsArr[handle].style.transform = "translate(-50%,100%)";
            }

            tooltipsArr[handle].textContent = `${Math.round(
                values[handle]
            ).toLocaleString("en-GB")} ${unit}`;
        }
    });

    slider.noUiSlider.on(
        "change",
        debounce(() => {
            load("jobs/search", "form:#search_form", "form:#search_form2");
            return false;
        })
    );
}

// filters accordion
const filterBoxes = document.querySelectorAll(".filter-box");
if (filterBoxes.length) {
    function toggleVisibility(box) {
        const boxContent = box.querySelector(".filter-box__content");

        if (box.classList.contains("open")) {
            boxContent.style.height = boxContent.scrollHeight + "px";
        } else {
            boxContent.style.height = "";
        }
    }

    filterBoxes[0].classList.add("open");

    toggleVisibility(filterBoxes[0]);

    filterBoxes.forEach((box) => {
        const headTrigger = box.querySelector(".filter-box__head");

        headTrigger.addEventListener("click", () => {
            box.classList.toggle("open");

            toggleVisibility(box);
        });
    });
}

/* Following Sidebar functionality */
const contentSticky = document.getElementById("content-sticky");
const rightSidebar = document.getElementById("sidebar");
if (rightSidebar) {
    const sidebarBuffer = rightSidebar.querySelector(".sidebar__buffer");
    const sidebarInner = rightSidebar.querySelector(".sidebar__inner");
    // const headerHeight = +getRootStyle("--header-height").replace("px", "");
    const headerHeight = header.scrollHeight;

    // gap on top and bottom of sticky sidebar
    const gap = 30;

    let lastScrollTop = 0;

    // if flag true === scrolling down else scrolling up
    let flag = null;

    // if flagHeight true === sidebar is higher than viewport height
    let flagHeight =
        document.querySelector(".sidebar__inner").getBoundingClientRect()
            .height >
        window.innerHeight - headerHeight;

    function calcHeightOfSidebar(flag) {
        const contentStickyTop = getPosition(contentSticky).y;
        const sidebarInnerTop = getPosition(sidebarInner).y;
        sidebarBuffer.style.height = `${sidebarInnerTop - contentStickyTop}px`;

        if (flag) {
            // scroll up
            sidebarInner.style.top = "";
            sidebarInner.style.bottom = `${
                -headerHeight -
                gap +
                window.innerHeight -
                sidebarInner.getBoundingClientRect().height
            }px`;
        } else {
            // scroll down
            sidebarInner.style.bottom = "";
            sidebarInner.style.top = `${
                Math.min(
                    window.innerHeight -
                        sidebarInner.getBoundingClientRect().height,
                    headerHeight
                ) - gap
            }px`;
        }
        flagHeight =
            sidebarInner.getBoundingClientRect().height >
            window.innerHeight - headerHeight;
    }

    function resetSticky() {
        sidebarInner.style.bottom = "";
        sidebarInner.style.top = `${headerHeight + gap}px`;
        sidebarBuffer.style.height = "0";
    }

    if (!flagHeight) {
        resetSticky();
    } else {
        calcHeightOfSidebar(false);
    }

    window.addEventListener("scroll", function () {
        let st = window.pageYOffset || document.documentElement.scrollTop;

        if (!flagHeight) {
            resetSticky();
        }

        if (st > lastScrollTop && !flag) {
            // scroll down
            calcHeightOfSidebar(flag);
            flag = true;
        } else if (st < lastScrollTop && flag) {
            // scroll up
            calcHeightOfSidebar(flag);
            flag = false;
        }
        lastScrollTop = st <= 0 ? 0 : st;
    });

    window.addEventListener("resize", function (e) {
        if (!flagHeight) {
            resetSticky();
        }

        calcHeightOfSidebar(true);
        calcHeightOfSidebar(false);
    });
}

/* ---- Sliders ---- */
// Gallery
const gallerySection = document.querySelector(".gallery-section");
if (gallerySection) {
    function makeMarquee(element, directionOption = "left") {
        var $mg = $(element).marquee({
            duration: 50000,
            delayBeforeStart: 0,
            direction: directionOption,
            duplicated: true,
            startVisible: true,
            pauseOnHover: true,
        });
    }

    const firstGallery = gallerySection.querySelector(".first");
    const secondGallery = gallerySection.querySelector(".second");

    if (firstGallery) {
        makeMarquee(firstGallery);
    }
    if (secondGallery) {
        makeMarquee(secondGallery, "right");
    }
}

const sectorSlider = document.querySelector(".sectors-swiper");
if (sectorSlider) {
    const slides = sectorSlider.querySelectorAll(".swiper-slide");

    new Swiper(sectorSlider, {
        slidesPerView: 1,
        spaceBetween: 15,
        loop: slides.length > 5,
        loopedSlides: 5,

        autoplay: {
            disableOnInteraction: false,
            delay: 3500,
            pauseOnMouseEnter: true,
        },

        navigation: {
            prevEl: ".swiper-btn-prev.sector-btn",
            nextEl: ".swiper-btn-next.sector-btn",
        },

        breakpoints: {
            600: {
                slidesPerView: 2,
            },

            990: {
                slidesPerView: 3,
            },

            1150: {
                slidesPerView: 4,
            },
        },

        on: {
            init: function (e) {
                if (window.innerWidth > 1024) {
                    VanillaTilt.init(e.slides, {
                        scale: 1.05,
                    });
                }
            },
        },
    });
}

const solutionsSlider = document.querySelector(".solutions-swiper");
if (solutionsSlider) {
    const slides = solutionsSlider.querySelectorAll(".swiper-slide");

    new Swiper(solutionsSlider, {
        slidesPerView: 1,
        spaceBetween: 40,

        autoplay: {
            disableOnInteraction: false,
            delay: 3500,
            pauseOnMouseEnter: true,
        },

        pagination: {
            el: ".solutions-pagination",
            clickable: true,
            dynamicBullets: slides.length > 6,
            dynamicMainBullets: 3,
        },

        breakpoints: {
            415: {
                slidesPerView: "auto",
            },
        },
    });
}

const latestBlogsSlider = document.querySelector(".latest-blog-swiper");
if (latestBlogsSlider) {
    const slides = latestBlogsSlider.querySelectorAll(".swiper-slide");

    new Swiper(latestBlogsSlider, {
        slidesPerView: 1,
        spaceBetween: 15,
        loop: slides.length > 5,
        loopedSlides: 5,

        autoplay: {
            disableOnInteraction: false,
            delay: 3500,
            pauseOnMouseEnter: true,
        },

        breakpoints: {
            600: {
                slidesPerView: 2,
            },

            990: {
                slidesPerView: 3,
            },
        },
    });
}

const clientsSlider = document.querySelector(".clients-swiper");
if (clientsSlider) {
    const slides = clientsSlider.querySelectorAll(".swiper-slide");

    new Swiper(clientsSlider, {
        slidesPerView: 2,
        spaceBetween: 5,
        loop: true,
        loopedSlides: 10,

        autoplay: {
            disableOnInteraction: false,
            delay: 3500,
            pauseOnMouseEnter: true,
        },

        navigation: {
            prevEl: ".swiper-btn-prev.clients-btn",
            nextEl: ".swiper-btn-next.clients-btn",
        },

        pagination: {
            el: ".clients-section__slider .swiper-pagination",
            clickable: true,
            dynamicBullets: slides.length > 6,
            dynamicMainBullets: 3,
        },

        breakpoints: {
            651: {
                slidesPerView: 3,
            },

            990: {
                slidesPerView: 4,
            },

            1024: {
                slidesPerView: 5,
            },
        },
    });
}

const testimonialsSlider = document.querySelector(".testimonials-swiper");
if (testimonialsSlider) {
    const slides = testimonialsSlider.querySelectorAll(".swiper-slide");

    const swiper = new Swiper(testimonialsSlider, {
        slidesPerView: "auto",
        spaceBetween: 28,
        loop: slides.length > 5,
        loopedSlides: 5,
        autoHeight: true,

        autoplay: {
            disableOnInteraction: false,
            delay: 3500,
            pauseOnMouseEnter: true,
        },

        pagination: {
            el: ".testimonials__slider .swiper-pagination",
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 3,
        },

        breakpoints: {
            415: {
                autoHeight: false,
            },

            651: {
                slidesPerView: 2,
                autoHeight: false,
            },

            1025: {
                slidesPerView: 2,
                autoHeight: false,

                pagination: {
                    dynamicMainBullets: 12,
                },
            },
        },

        on: {
            breakpoint: function (swiper, breakpointParams) {},
        },
    });

    let swiperStatus = false;
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((elem) => {
                if (elem.target.classList.contains("testimonials-swiper")) {
                    swiperStatus = elem.isIntersecting;
                }
            });

            if (swiperStatus) {
                swiper.autoplay.start();
                swiper.update();
            } else {
                swiper.autoplay.stop();
                swiper.update();
            }
        },
        { threshold: 0.5 }
    );

    observer.observe(testimonialsSlider);
}

const teamSlider = document.querySelector(".team-swiper");
if (teamSlider) {
    const slides = teamSlider.querySelectorAll(".swiper-slide");

    const swiper = new Swiper(teamSlider, {
        slidesPerView: "auto",
        spaceBetween: 32,
        loop: slides.length > 5,
        loopedSlides: 5,

        autoplay: {
            disableOnInteraction: false,
            delay: 3500,
            pauseOnMouseEnter: true,
        },

        navigation: {
            prevEl: ".swiper-btn-prev.team-btn",
            nextEl: ".swiper-btn-next.team-btn",
        },
    });

    window.teamSlider = swiper;

    let swiperStatus = false;
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((elem) => {
                if (elem.target.classList.contains("team-swiper")) {
                    swiperStatus = elem.isIntersecting;
                }
            });

            if (swiperStatus) {
                swiper.autoplay.start();
                swiper.update();
            } else {
                swiper.autoplay.stop();
                swiper.update();
            }
        },
        { threshold: 0.5 }
    );

    observer.observe(teamSlider);
}

const blogsSection = document.querySelector(".blogs-section");
if (blogsSection) {
    const slides = blogsSection.querySelectorAll(".blogs-swiper .swiper-slide");

    const blogSlider = new Swiper(".blogs-swiper", {
        slidesPerView: 1,
        effect: "fade",
        loop: slides.length > 2,
        speed: 600,
        watchSlidesProgress: true,
        allowTouchMove: false,
        loopedSlides: 10,

        fadeEffect: {
            crossFade: true,
        },
    });

    const blogPreviewSlider = new Swiper(".blogs-preview-swiper", {
        slidesPerView: 1,
        spaceBetween: 15,
        speed: 600,
        loop: slides.length > 2,
        loopedSlides: 10,

        rewind: slides.length < 3,

        thumbs: {
            swiper: blogSlider,
        },

        autoplay: {
            disableOnInteraction: false,
            delay: 3500,
            pauseOnMouseEnter: true,
        },

        navigation: {
            prevEl: ".swiper-btn-prev.blog-btn",
            nextEl: ".swiper-btn-next.blog-btn",
        },
    });
}
